// 节流函数
export const throttle = (fn: Function, delay: number) => {
  let last = 0
  return (...args: any[]) => {
    const now = Date.now()
    if (now - last > delay) {
      fn.apply(this, args)
      last = now
    }
  }
}
// 防抖函数，可以立即触发，但是在delay时间内再次触发，会重新计算时间
export const debounce = (fn: Function, delay: number, immediate = true) => {
  let timer: any
  return (...args: any[]) => {
    if (timer) clearTimeout(timer)
    if (immediate) {
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, delay)
      if (callNow) fn.apply(this, args)
    } else {
      timer = setTimeout(() => {
        fn.apply(this, args)
      }, delay)
    }
  }
}

export const Device = () => {
  if (process.client) {
    const ua = navigator.userAgent
    return {
      isMobile: /(Android)/i.test(ua) || /(iPhone|iPod|iPad|iOS)/i.test(ua),
      isAndroid: /(Android)/i.test(ua),
      isIOS: /(iPhone|iPod|iPad|iOS)/i.test(ua)
    }
  } else {
    return {
      isMobile: false,
      isAndroid: false,
      isIOS: false
    }
  }
}
// 防止Xss攻击
export const escapeHtml = (str: string) => {
  const escapeMap: any = {
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '&': '&amp;'
  }
  return str.replace(/[<>"'&]/g, (match) => {
    return escapeMap[match]
  })
}
export const formatLang = (lang?: string, langList = []) => {
  if (!lang) return 'en'
  const acceptableLang =
    langList.length > 0
      ? langList
      : ['pt-br', 'ind', 'en', 'es', 'bn', 'hi', 'ar', 'vn', 'ru', 'zh-tw', 'zh-cn', 'th', 'my', 'tr', 'ur', 'en-pk'] // TODO 语言列表根据实际项目预设
  if (lang.includes('pk')) {
    lang = 'en-pk'
  }
  if (lang.includes('vi')) {
    lang = 'vn'
  }
  if (lang === 'zh') {
    lang = 'zh-tw'
  } else if (lang.includes('zh')) {
    lang = lang.toLowerCase()
    if (lang !== 'zh-tw' && lang !== 'zh-cn') {
      lang = 'zh-tw'
    }
  }
  if (lang.includes('pt')) {
    lang = 'pt-br'
  }
  if (lang.includes('id')) {
    lang = 'ind'
  }
  for (let i = 0; i < acceptableLang.length; i++) {
    acceptableLang[i] = acceptableLang[i].toLowerCase()

    if (lang.includes(acceptableLang[i])) {
      return acceptableLang[i]
    }
  }
  return 'en'
}
// 深拷贝
export const deepClone = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  let result: any
  if (Array.isArray(obj)) {
    result = []
  } else {
    result = {}
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = deepClone(obj[key])
    }
  }
  return result
}

export const formatTime = (timestamp: number, type = `dd/mm/yyyy`, unit = 1000) => {
  const date = new Date(timestamp * unit)
  const [year, month, day] = [
    date.getFullYear(),
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1),
    date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
  ]
  return type.replace('yyyy', String(year)).replace('mm', String(month)).replace('dd', String(day))
}
export const formatTimeByLanguage = (timestamp: number, lang: string, unit = 1000) => {
  return formatTime(timestamp, lang === 'zh' || lang === 'ja' || lang === 'kr' ? 'yyyy/mm/dd' : 'dd/mm/yyyy', unit)
}
export const getElementToPageTop = (el: HTMLElement): number => {
  if (el.parentElement) {
    return getElementToPageTop(el.parentElement) + el.offsetTop
  }
  return el.offsetTop
}
export function stopPropagation(event: any) {
  event.stopPropagation()
}
export function preventDefault(event: any, isStopPropagation = true) {
  if (typeof event.cancelable !== 'boolean' || event.cancelable) {
    event.preventDefault()
  }

  if (isStopPropagation) {
    stopPropagation(event)
  }
}
export function sortArrayByKey(array: any[], key: string, order: 'asc' | 'dec' = 'asc') {
  return array.sort((a, b) => {
    if (order === 'asc') {
      return a[key] - b[key]
    } else {
      return b[key] - a[key]
    }
  })
}

export function downloadFile(url: string, filename?: string) {
  const link = document.createElement('a')
  filename = filename || url?.substring(url?.lastIndexOf('/') + 1, url.lastIndexOf('.'))
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
